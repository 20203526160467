<template>
    <div class="bg-white shadow-lg rounded-lg mr-3 ml-3 pb-5 mt-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">猜你喜歡資訊</div>
                <div class="inputs">
                    <div class="input">
                        <div class="profile" @click="goProfile">
                            <img class="profile-img" :src="inputs.avatar" alt="" />
                            <div class="name">
                                {{ inputs.name }}
                            </div>
                        </div>
                    </div>
                    <div class="input">
                        <div class="label">啟用狀態</div>
                        <el-select v-model="inputs.activated" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in openOption" :key="idx" :value="item.value" :label="item.label"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">使用期間</div>
                        <el-select v-model="inputs.duration" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in duringOption" :key="idx" :value="item.value" :label="item.label"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">期間範圍</div>
                        <el-date-picker
                            v-model="inputDates"
                            :disabled="!inputs.duration"
                            style="width: 100%"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="black-btn-outline-lg text-md mr-5" @click="goBack">返回列表</button>
            <button v-permission="['delete']" class="orange-btn-outline-800-lg text-md mr-5" @click="deleteData">刪除資料</button>
            <button v-permission="['update']" class="orange-btn-800-lg text-md" @click="update">儲存變更</button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
export default {
    name: "EditPromo",
    components: {
        LoadingComponent,
    },
    computed: {
        // 啟用選項
        openOption() {
            return [
                { label: "啟用", value: true },
                { label: "停用", value: false },
            ];
        },
        // 使用期間選項
        duringOption() {
            return [
                { label: "不限使用期間", value: false },
                { label: "限定使用期間", value: true },
            ];
        },
    },
    data() {
        return {
            id: "",
            loading: false,
            disabled: false,
            // 輸入匡狀態
            inputs: {
                id: 0,
                avatar: "",
                name: "",
                actived: false,
                duration: true,
                start: "",
                end: "",
            },
            // 額外處理輸入
            inputDates: [],
        };
    },
    methods: {
        // 刪除 API
        deleteData() {
            const opts = {
                title: "刪除資料",
                message: "所選資料刪除後將無法復原",
            };
            this.$pop.popConfirm(opts).then(
                // 確認
                () => {
                    this.$api
                        .DeleteNewbies(this.id)
                        .then(() => {
                            this.$message({
                                type: "success",
                                message: "刪除成功",
                            });
                            this.goBack();
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$message({
                                type: "error",
                                message: "刪除失敗",
                            });
                        });
                },
                // 取消
                () => {
                    return;
                }
            );
        },
        // 返回列表
        goBack() {
            this.$router.push({ name: "newcomer_list" });
        },
        // 前往服務商頁面
        goProfile() {
            window.open(`/#/user_list/provider_update/${this.inputs.id}`);
        },
        // 更新
        update() {
            const { end, start, activated, duration } = this.inputs;
            if (duration) {
                if (!start || !end) {
                    alert("使用期間未填寫完整");
                    return;
                }
            }
            this.loading = true;
            let request = {};
            if (duration) {
                request = {
                    activated,
                    duration,
                    start,
                    end,
                };
            } else {
                request = {
                    activated,
                    duration,
                };
            }

            this.$api
                .UpdateNewbies(this.id, request)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "編輯成功",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        type: "error",
                        message: "編輯失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        // 監聽 url
        "$route.params.id"() {
            window.location.reload();
        },
        // 監聽 element-ui 日期輸入匡，將值設定於輸入匡變數中
        inputDates(val) {
            if (val === null || val.length === 0) {
                this.inputs.start = "";
                this.inputs.end = "";
                return;
            }
            this.inputs.start = val[0];
            this.inputs.end = val[1];
        },
    },
    async mounted() {
        this.id = this.$route.params.id;
        this.loading = true;
        const { status, data } = await this.$api
            .GetNewbiesList()
            .then((res) => {
                const data = res.data.providers.find((i) => i.banana_id == this.id);
                if (!data) {
                    this.$router.push({ name: "newcomer_list" });
                }
                return { status: res.status, data };
            })
            .catch(() => {
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
                return;
            })
            .finally(() => {
                this.loading = false;
            });
        if (status == 200) {
            this.inputs = {
                id: data.id,
                avatar: data.avatar,
                name: data.name,
                activated: data.activated,
                duration: data.duration?.start ? true : false,
                start: data.duration ? moment(data.duration?.start).format("YYYY-MM-DD") : "",
                end: data.duration ? moment(data.duration?.end).format("YYYY-MM-DD") : "",
            };
            if (data.duration) {
                this.inputDates = [
                    data.duration ? moment(data.duration?.start).format("YYYY-MM-DD") : "",
                    data.duration ? moment(data.duration?.end).format("YYYY-MM-DD") : "",
                ];
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            .input {
                width: 48%;
                padding: 10px 0px;
                .profile {
                    display: flex;
                    align-items: center;
                    .profile-img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                    .name {
                        color: #ff5733;
                        text-decoration: underline;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                flex: 1;
                width: 50%;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
